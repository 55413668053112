import Cookies from 'js-cookie'

const state = () => ({
    appLocale: Cookies.get('locale'),
});

const mutations = {
    setAppLocale: (state, payload) => {
        state.appLocale = payload
    },
};

const actions = {
    async changeAppLocale ({commit}, payload) {
        Cookies.set('locale', payload, { expires: 365, secure: true });
        commit('setAppLocale', payload);
    },
};

const getters = {
    getAppLocale: state => {
        return state.appLocale;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
