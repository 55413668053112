import "./bootstrap";
import "../css/app.scss";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import { appConfig } from "@/Config/appConfig.js";
import { Can } from '@/Config/appCan.js'
const appName = appConfig.name;

// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
// make sure to also import the coresponding css
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files
import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
import store from "@/Stores/index";

// const MONKEYiDESIGNTheme = {
//     dark: false,
//     colors: {
//         background: "#FFFFFF",
//         surface: "#FFFFFF",
//         primary: "#008858",
//         "primary-darken-1": "#008858",
//         secondary: "#413d3c",
//         "secondary-darken-1": "#413d3c",
//         error: "#ff0000",
//         info: "#2196F3",
//         success: "#4CAF50",
//         warning: "#FB8C00",
//         gray: "#363534",
//     },
// };

// import 'vuetify/dist/vuetify.min.css';
import "vuetify/styles";

const vuetify = createVuetify({
    // theme: {
    //     defaultTheme: 'MONKEYiDESIGNTheme',
    //     themes: {
    //         MONKEYiDESIGNTheme,
    //     },
    // },
    theme: {
        defaultTheme: "light",
        themes: {
            light: {
                dark: false,
                colors: {
                    background: "#FFFFFF",
                    surface: "#FFFFFF",
                    primary: "#008858",
                    "primary-darken-1": "#006C47",
                    secondary: "#413d3c",
                    "secondary-darken-1": "#35302F",
                    accent: "#25316A",
                    error: "#E86674",
                    warning: "#FB8C00",
                    info: "#2196F3",
                    success: "#4CAF50",
                    orange: "#FF7A0D",
                    golden: "#A68C59",
                    badge: "#F5528C",
                    customPrimary: "#ff3545",
                    gray: "#363534",
                },
            },
            dark: {
                dark: true,
                colors: {
                    primary: "#006C47",
                },
            },
        },
    },
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
            fa,
        },
    },
    components,
    directives,
});

import i18n from '@/Config/i18n.js';

//Import Sweetalert2
import Swal from 'sweetalert2'
window.Swal = Swal
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
})
window.Toast = Toast
import moment from 'moment';

const myPlugins = {
    install(app) {
        // Attach moment to Vue 3 global properties, to make it globally available.
        // https://vuejs.org/guide/reusability/plugins.html#writing-a-plugin
        app.config.globalProperties.$moment = moment;
    }
}
import VueGoogleMaps from '@fawmi/vue-google-maps';

const VueGoogleMapsOptions = {
    load: {
        key: import.meta.env.VITE_GMAP_KEY,
        libraries: "places"
    },};
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(vuetify)
            .use(store)
            .use(Can)
            .use(i18n)
            .use(myPlugins)
            .use(VueGoogleMaps, VueGoogleMapsOptions)
            .mount(el);
    },
    progress: {
        // The delay after which the progress bar will appear, in milliseconds...
        delay: 250,

        // The color of the progress bar...
        color: "#FB8C00",

        // Whether to include the default NProgress styles...
        includeCSS: true,

        // Whether the NProgress spinner will be shown...
        showSpinner: false,
    },
});
