import { usePage } from '@inertiajs/vue3'

export const Can = {
    install: (v) => {
        const page = usePage()
        const can = (permission) => {
            // console.log(page.props.auth.roles.includes("super-admin"), "can roles>>>>>");
            // console.log(page.props.auth.permissions.includes(permission), "can>>>>>");
            // console.log(page.props.auth.testing);

            if(page.props.auth.roles.includes("super-admin")){
                return true;
            }
            return page.props.auth.permissions.includes(permission)
        }
        const canRole = (role) => {

            if(page.props.auth.roles.includes("super-admin")){
                return true;
            }
            return page.props.auth.roles.includes(role)
        }

        v.mixin({
            methods: { can, canRole }
        })
    }
}
