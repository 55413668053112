import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import store from '@/Stores/index.js'

// console.log(document.cookie.split('=')[1], "cookie>>>>.");

const i18n = createI18n({
    locale: store.getters['Locales/getAppLocale'] === undefined? 'en' : store.getters['Locales/getAppLocale'],
    globalInjection: true,
    messages: messages
})

export default i18n
