import { createStore } from "vuex";

import DummyUsers from './modules/DummyUsers/users.js';
import Locales from './modules/language.js';

export default new createStore({
    stric: true,
    modules: {
        DummyUsers,
        Locales
    }
})


// https://stackoverflow.com/questions/68912818/vuex-is-not-able-to-find-namespace-of-module
