const state = () => ({
    users: [],
});

const mutations = {
    setssers: (state, payload) => state.users = payload,
};
const actions = {};

const getters = {
    getData: state => state.users,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
